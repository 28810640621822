import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'

export interface Props {
  alignment: string
  cta?: ButtonProps[]
  description?: string
  image?: ImageProps
  subtitle?: string
  title?: string
}

export const ZigZag = memo(function ZigZag({
  alignment,
  cta,
  description,
  image,
  subtitle,
  title,
}: Props) {
  if (!title || !description) {
    return null
  }

  return (
    <Container className={`zz-${alignment}`} dial={5} row wrap stretch>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <ImageWrap className="zz-image-wrap">
          {image ? (
            <LazyLoad>
              <Image
                src={image.src}
                srcSet={image.srcSet}
                width={image.width}
                height={image.height}
                alt={image.alt}
              />
            </LazyLoad>
          ) : null}
          <Media greaterThanOrEqual="ipadVertical">
            {title ? <Title>{title}</Title> : null}
          </Media>
        </ImageWrap>
        <TextWrap>
          <Media lessThan="ipadVertical">
            {title ? <Title>{title}</Title> : null}
          </Media>
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
          {cta
            ? cta.map((item, index) => (
                <Button key={index} {...item} variant="corners" />
              ))
            : null}
        </TextWrap>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: relative;
  &.zz-left {
    .zz-image-wrap {
      order: 2;
      > h2 {
        right: auto;
        left: 0;
      }
    }
  }

  @media (max-width: 767px) {
    background: none;
    margin-bottom: 3.75rem;
    &.zz-left {
      .zz-image-wrap {
        order: 0;
      }
    }
  }
`

const ImageWrap = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  min-height: 62vh;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    min-height: auto;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 66%;
  }
`

const Title = styled.h2`
  width: 22.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
  padding: 3rem;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 2;
  transform: translateY(-50%);

  @media (max-width: 1023px) {
    width: 50%;
    font-size: 1.375rem;
    line-height: 2rem;
    padding: 2.25rem;
  }

  @media (max-width: 767px) {
    width: auto;
    background: none;
    margin-bottom: 1.125rem;
    padding: 0;
    position: relative;
    top: auto;
    right: auto;
    transform: none;
  }
`

const TextWrap = styled.div`
  width: 50%;
  padding: 8.875rem 8.472vw;

  @media (max-width: 1023px) {
    padding: 5.625rem 1.5rem 5.625rem 3rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 3rem 1.5rem 0;
  }
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.8px;
  margin-bottom: 1.25rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    margin-bottom: 1.5rem;
  }
`

const Description = styled.div`
  max-width: 25.556vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;

  @media (max-width: 1023px) {
    max-width: none;
  }
`
