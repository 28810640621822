import styled from '@emotion/styled'
import { Separator } from 'app/components/Common/Separator'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Props as ZigZagProps, ZigZag } from './ZigZag'

export interface Props {
  stripes: ZigZagProps[]
}

export const ZigZagList = memo(function ZigZagList({ stripes }: Props) {
  if (stripes.length < 1) {
    return null
  }

  return (
    <Container>
      <Separator />
      {uniqBy(stripes, 'title').map((item, index) => (
        <Fade bottom distance="3.75rem" key={index}>
          <ZigZag {...item} />
        </Fade>
      ))}
    </Container>
  )
})

const Container = styled.section`
  margin-top: 6rem;

  @media (max-width: 1023px) {
    margin-top: 3.75rem;
  }
`
